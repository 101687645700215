<template>
  <div class="field">
    <label class="field__label" :for="name" :class="{ active: visible }">{{ label }}</label>
    <input 
      :type="type" 
      v-model="innerValue" 
      class="field__input" 
      :id="name" 
      :placeholder="label"
      @focusin="focused = true" 
      @focusout="focused = false">
    <template v-if="validator?.$errors">
      <p v-for="(error, i) in validator?.$errors" :key="i" class="field__error">
        {{ error.$message }}
      </p>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { BaseValidation } from '@vuelidate/core';

const props = defineProps({
  modelValue: {
    type: String as PropType<string|Ref<string>>,
    default: ''
  },
  name: {
    type: String as PropType<string>,
    default: ''
  },
  label: {
    type: String as PropType<string>,
    default: ''
  },
  type: {
    type: String as PropType<string>,
    default: 'text'
  },
  validator: {
    type: Object as PropType<BaseValidation>
  }
});

const emit = defineEmits(['update:modelValue']);

const innerValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const focused = ref<boolean>(false);
const visible = computed<boolean>(() => focused.value || innerValue.value.length > 0);
</script>
